import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const PrivateRoute = ({ children }) => {
  const user = localStorage.getItem("user"); // Check for user authentication
  const user_permissions = localStorage.getItem("user_permissions");
  const currentUrl = window.location.href;
  const navigate = useNavigate(); // Hook for programmatic navigation

  // Check for user authentication
  if (user && user_permissions) {
    const permissions = JSON.parse(user_permissions);
    const hasPermission = permissions.some((permission) => currentUrl === permission.application.url);

    if (hasPermission) {
      return children; // Render children if the user has permission for the current URL
    } else {
      // Show alert and then navigate after the alert is closed
      Swal.fire({
        icon: "error",
        text: "Denied Access",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/"); // Redirect to the home page after the alert
      });
      return null; // Prevent rendering children while the alert is shown
    }
  } else {
    return <Navigate to="/" />; // Redirect to home if not authenticated
  }
};

export default PrivateRoute;
