import React, { useState, useEffect } from "react";
import Footer from "./main/footer";
import Nav from "./main/nav";
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";
import formatToDateTimeLocal from "./main/formatToDateTimeLocal";
import formatToDateTime from "./main/formatToDateTime";
import "../Pages.css";

export default function Index() {
  const [preview, setPreview] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [scannedData, setScannedData] = useState(null);
  const [isScanning, setIsScanning] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [disable, setDisable] = useState(false);

  const [Error, setError] = useState("");

  const [ID, setID] = useState(null);
  const [PermitNumber, setPermitNumber] = useState(null);
  const [ContainerNumber, setContainerNumber] = useState(null);
  const [ContainerSize, setContainerSize] = useState(null);
  const [ContainerType, setContainerType] = useState(null);
  const [ContainerStatus, setContainerStatus] = useState(null);
  const [IsPermitValid, setIsPermitValid] = useState(null);
  const [PermitDateTime, setPermitDateTime] = useState(null);
  const [VehicleNumber, setVehicleNumber] = useState(null);
  const [SlineCode, setSlineCode] = useState(null);
  const [CtrLifeNumber, setCtrLifeNumber] = useState(null);
  const [DamageStatus, setDamageStatus] = useState(null);
  const [LDD_MT_Flg, setLDD_MT_Flg] = useState(null);

  const [Transections, setTransections] = useState([]);

  useEffect(() => {
    LastTransection();
  }, []);

  const startQrScanner = () => {
    setIsScanning(true);
    const html5QrCode = new Html5Qrcode("reader");

    // Check if navigator.mediaDevices is available

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          // Check if we have an environment (rear) camera
          const environmentCamera = devices.find(
            (device) =>
              device.kind === "videoinput" && device.facing === "environment"
          );

          const constraints = environmentCamera
            ? { deviceId: { exact: environmentCamera.deviceId } }
            : { facingMode: "environment" }; // Use 'environment' to access the rear camera on mobile devices

          // Start QR scanner with constraints
          html5QrCode
            .start(
              constraints,
              { fps: 10, qrbox: { width: 250, height: 250 } },
              (decodedText) => {
                // Handle scanned data
                setScannedData(decodedText);
                handleSubmit(decodedText);
                setPreview(true);
                setIsScanning(false);
                html5QrCode.stop();
              },
              (errorMessage) => {
                console.error("ScanningError:", errorMessage);
                // setError("ScanningError:", errorMessage);
              }
            )
            .catch((err) => {
              console.error("Error starting QR scanner:", err);
              setError("Error starting QR scanner:", err);
              setIsScanning(false);
            });
        })
        .catch((err) => {
          console.error("Error enumerating devices:", err);
          setError("Error enumerating devices: ", err);

          setIsScanning(false);
        });
    } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Fallback for older browsers or if enumerateDevices doesn't work
      const constraints = { video: { facingMode: "environment" } };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // Start QR scanner with the video stream
          html5QrCode
            .start(
              { facingMode: "environment" },
              { fps: 10, qrbox: { width: 250, height: 250 } },
              (decodedText) => {
                // Handle scanned data
                setScannedData(decodedText);
                handleSubmit(decodedText);
                setPreview(true);
                setIsScanning(false);
                html5QrCode.stop();
              },
              (errorMessage) => {
                console.error("ScanningError:", errorMessage);
              }
            )
            .catch((err) => {
              console.error("Error starting QR scanner:", err);

              setError("Error starting QR scanner: ", err);
              setIsScanning(false);
            });
        })
        .catch((err) => {
          console.error("Error accessing the camera:", err);
          setError("Error accessing the camera: ", err);

          setIsScanning(false);
        });
    } else {
      console.error("Media devices are not supported on this device.");
      setError("Media devices are not supported on this device ");
      setIsScanning(false);
    }
  };

  const handleSubmit = async (decodedText) => {
    const url = `https://easyaiconnect.com/backend/api/get/permit_detail/${decodedText}?gate_no=1&type=IN`;
    try {
      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data && response.data.data) {
        const Data = response.data.data;
        console.log("Response from server:", Data);

        setID(Data?.id);
        setPermitNumber(Data?.permit_no);
        setContainerNumber(Data?.container_no);
        setContainerSize(Data?.container_size);
        setContainerType(Data?.container_type);
        setContainerStatus(Data?.ContainerStatus);

        setIsPermitValid(formatToDateTimeLocal(Data?.IsPermitValid || ""));
        setPermitDateTime(formatToDateTimeLocal(Data?.PermitDateTime || ""));
        setCtrLifeNumber(formatToDateTimeLocal(Data?.CtrLifeNumber || ""));

        setVehicleNumber(Data?.vehicle_no);
        setSlineCode(Data?.SlineCode);
        setDamageStatus(Data?.is_container_damage);
        setLDD_MT_Flg(Data?.LDD_MT_Flg);
      } else {
        console.warn("No data found for the given input.");
      }
    } catch (error) {
      console.error("Error in Permit Data Fetch :", error);
      setError(
        `Error in Permit Data Fetch : ${
          error.response?.status || "Unknown"
        } - ${error.message}`
      );
    }
  };

  const handlePostSubmit = async () => {
    const url = `https://easyaiconnect.com/backend/api/gate_transection/update`;

    const payload = {
      id: ID,
      permit_no: PermitNumber,
      container_no: ContainerNumber,
      container_size: ContainerSize,
      vehicle_no: VehicleNumber,
      is_container_damage: DamageStatus,
    };

    try {
      const response = await axios.post(url, payload, {
        headers: { "Content-Type": "application/json" }, // Config object
      });

      if (response.data && response.data.data) {
        console.log("Response from server:", response.data);
        window.location.reload();
        alert("Data Updated SuccessFully.");
      } else {
        console.log("Error in Response Data :", response.data);
        setError(`Error in Response Data :  ${response.data}`);
      }
    } catch (error) {
      console.error("Error in Data Submit:", error);
      setError(
        `Error in Data Submit: ${error.response?.status || "Unknown"} - ${
          error.message
        }`
      );
    }
  };



  const handleEdit = () => {
    setReadOnly(false);
    setDisable(true);
  };

  const handleCancelEdit = () => {
    setReadOnly(true);
    setDisable(false);
  };

  const LastTransection = async (decodedText) => {
    const url = `https://easyaiconnect.com/backend/api/gate/last_transections`;
    try {
      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data && response.data) {
        // const Data = response.data;
        setTransections(response.data.data);
        // console.log("Response from server:", );
      }
    } catch (error) {
      setError(
        `Error in Permit Data Fetch : ${
          error.response?.status || "Unknown"
        } - ${error.message}`
      );
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <Nav />
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-md-10 m-auto">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            {Error && (
                              <div
                                className="alert alert-danger mt-3"
                                role="alert"
                              >
                                {Error}
                              </div>
                            )}
                            <h4 className="text-primary">Gate Application</h4>
                            {/* <div
                                className="col-auto ms-auto align-items-center d-flex" onClick={startQrScanner}disabled={isScanning}>
                                <img
                                  src="/camra.png"
                                  alt=""
                                  className="qrImg"
                                />
                                <h3 className="mb-0">Scan Permit</h3>
                              </div> */}
                          </div>
                          {!preview && (
                            <div className="col-md-12 mb-2">
                              <div className="col-md-4 m-auto text-center QrScanBox">
                                {/* <button
                                  className="btn btn-primary"
                                  onClick={startQrScanner}
                                  disabled={isScanning}
                                >
                                  {isScanning
                                    ? "Scanning..."
                                    : "Open Camera for QR"}
                                </button> */}
                                {isScanning ? (
                                  "Scanning..."
                                ) : (
                                  <div
                                    onClick={startQrScanner}
                                    disabled={isScanning}
                                  >
                                    <img
                                      src="/camra.png"
                                      alt=""
                                      className="qrImg"
                                    />
                                    <h4>Scan Permit</h4>
                                  </div>
                                )}
                                {isScanning ? (
                                  <>
                                    <a href="?" className="btn btn-danger ms-3">
                                      Cancle
                                    </a>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div
                                  id="reader"
                                  className="mt-3 m-auto"
                                  style={{
                                    width: "300px",
                                    height: "300px",
                                    display: isScanning ? "block" : "none",
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {preview && (
                            <>
                              {/* <div className="card">
                                <div className="card-body"> */}

                              <hr />
                              <div className="row p-5">
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Permit"
                                      readOnly={true}
                                      disabled={disable}
                                      value={PermitNumber}
                                    />
                                    <label htmlFor="Permit">
                                      Permit Number
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Container"
                                      readOnly={readOnly}
                                      value={ContainerNumber}
                                      onKeyUp={(e) =>
                                        setContainerNumber(e.target.value)
                                      }
                                      onChange={(e) =>
                                        setContainerNumber(e.target.value)
                                      }
                                    />
                                    <label htmlFor="Container">
                                      Container Number
                                    </label>
                                  </div>
                                </div>

                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={readOnly}
                                      value={ContainerSize}
                                      onKeyUp={(e) =>
                                        setContainerSize(e.target.value)
                                      }
                                      onChange={(e) =>
                                        setContainerSize(e.target.value)
                                      }
                                    />
                                    <label htmlFor="">Container Size</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={ContainerType}
                                    />
                                    <label htmlFor="">Container Type</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={ContainerStatus}
                                    />
                                    <label htmlFor="">Container Status</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={IsPermitValid}
                                    />
                                    <label htmlFor="">Is Permit Valid </label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={PermitDateTime}
                                    />
                                    <label htmlFor="">Permit Date Time</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={readOnly}
                                      value={VehicleNumber}
                                      onKeyUp={(e) =>
                                        setVehicleNumber(e.target.value)
                                      }
                                      onChange={(e) =>
                                        setVehicleNumber(e.target.value)
                                      }
                                    />
                                    <label htmlFor="">Vehicle Number</label>
                                  </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={CtrLifeNumber}
                                    />
                                    <label htmlFor="">Ctr Life Number</label>
                                  </div>
                                </div>
                                {readOnly && (
                                  <div className="col-sm-6 mb-4">
                                    <div className="form-floating form-floating-outline">
                                      <input
                                        type="text"
                                        className="form-control"
                                        readOnly={readOnly}
                                        value={DamageStatus}
                                      />
                                      <label htmlFor="">Damage Status</label>
                                    </div>
                                  </div>
                                )}
                                {!readOnly && (
                                  <div className="col-sm-6 mb-4">
                                    <label htmlFor="">Damage Status</label>
                                    <div className="d-flex gap-2 my-2">
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="damage"
                                          id="Yes"
                                          checked={DamageStatus === "Yes"}
                                          onChange={() =>
                                            setDamageStatus("Yes")
                                          }
                                        />
                                        <label
                                          class="form-check-label"
                                          for="Yes"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="damage"
                                          id="No"
                                          checked={DamageStatus === "No"}
                                          onChange={() => setDamageStatus("No")}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="No"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="col-sm-6 mb-4">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly={true}
                                      disabled={disable}
                                      value={LDD_MT_Flg}
                                    />
                                    <label htmlFor="">LDD_MT_Flg</label>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex gap-3">
                                  <button
                                    className="btn btn-label-primary"
                                    onClick={handlePostSubmit}
                                  >
                                    Submit
                                  </button>
                                  {readOnly && (
                                    <button
                                      className="btn btn-label-info"
                                      onClick={handleEdit}
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {!readOnly && (
                                    <button
                                      className="btn btn-label-danger"
                                      onClick={handleCancelEdit}
                                    >
                                      Cancel Edit
                                    </button>
                                  )}

                                  <a href="" className="btn btn-danger ms-auto">
                                    {" "}
                                    Scan Another Permit
                                  </a>
                                </div>
                              </div>
                              {/* </div>
                              </div> */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card mt-4 p-3">
                      <div className="container">
                        <h5>Last 5 Transections</h5>
                        <table class="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">id</th>
                              <th scope="col">Type</th>
                              <th scope="col">Permit No</th>
                              <th scope="col">Vehicle No</th>
                              <th scope="col">Date Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Transections &&
                              Transections.map((data, index) => (
                                <tr key={index}>
                                  <th scope="row">{data.id}</th>
                                  <td>{data.type}</td>
                                  <td>{data.permit_no}</td>
                                  <td>{data.vehicle_no}</td>
                                  <td>{formatToDateTime(data.created_at) }</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
      </div>
    </>
  );
}
