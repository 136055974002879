import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Header from "./main/header";
import Footer from "./main/footer";
import Nav from "./main/nav";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "../Pages.css";

export default function IndexR() {
  const [advanceSummeryTrain, setAdvanceSummeryTrain] = useState([]);
  const [TrainData, setTrainData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wagon, setWagon] = useState(null);
  const [train, setTrain] = useState(null);
  const [container, setContainer] = useState(null);

  const [editAbleSeal, setEditAbleSeal] = useState(null);
  const [seal, setSeal] = useState(null);
  const [editAbleDamage, setEditAbleDamage] = useState(null);
  const [isDamage, setISDamage] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData();

    setEditAbleSeal(null);
    setSeal(null);
    setEditAbleDamage(null);
    setISDamage(false);
  }, [wagon, container]);

  // useEffect(() => {
  //   console.log("Updated AdvanceSummeryTrain:", advanceSummeryTrain);
  // }, [advanceSummeryTrain]);

  const fetchData = async () => {
    let url;
    url = `https://easyaiconnect.com/backend/api/rake/train/containers`;
    if (train) {
      url += `/${train}`;
    }
    if (wagon) {
      url += `?wagon_no=${wagon}`;
    }else{
      url += `?`;
    }
    if (container) {
      url += `&container_no=${container}`;
    }
    setLoading(true);

    try {
      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      // console.log("Response data:", response.data);
      if (response.data && response.data.data) {
        setAdvanceSummeryTrain(response.data.data);
        setTrainData(response.data.tranData);
      } else {
        console.warn("No data found for the given input.");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: `Error in Data Submit: ${error.message}`,
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false); 
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    let wagon_no = e.target.wagon.value;
    let container_no = e.target.container.value;
    setWagon(wagon_no);
    setContainer(container_no);
    fetchData();
  };

  const handleDamageClick = (id) => {
    Swal.fire({
      // title: "Are you sure?",
      text: "Do you want to mark this as damaged?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "Yes"
        setEditAbleDamage(id);
        setISDamage(true);
      } else if (result.isDenied) {
        // User clicked "No"
        setISDamage(false);
      } else if (result.isDismissed) {
        // User clicked "Cancel"
        setISDamage(false); // Handle cancel logic if needed
      }
    });
  };

  const handleVerify = (params) => {
    let seal_no;
    let damage;

    if (editAbleSeal === params.id) {
      seal_no = seal;
    } else {
      seal_no = params.ccls_SEAL_NO;
    }

    if (editAbleDamage === params.id) {
      damage = isDamage ? "Yes" : "No";
    } else {
      damage = params.is_container_damage;
    }

    const payload = {
      id: params.id,
      container_no: params.container_no,
      seal_no: seal_no,
      is_container_damage: damage,
      status: "1", 
    };

    const submitData = async () => {
      const url = `https://easyaiconnect.com/backend/api/rake/transection/update`;

      try {
        const response = await axios.post(url, payload, {
          headers: { "Content-Type": "application/json" },
        });

        // console.log(response);
        if(response.data && response.data.status){
          Swal.fire({
            icon: "success",
            text: response.data.status,
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            // window.location.reload();
            fetchData();
          });
        }

      } catch (error) {
        Swal.fire({
          icon: "error",
          text: `Error in Data Submit: ${error.message}`,
          timer: 3000,
          showConfirmButton: false,
        });
      }
    };

    setLoading(true);
    submitData();
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
          style={{ zIndex: 9999 }}
        >
          <div className="sk-chase sk-primary display-1">
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
            <div className="sk-chase-dot" />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <Nav />
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-md-11 m-auto">
                    <div className="card">
                      <div className="card-body">
                        <form
                          className="row align-items-center"
                          onSubmit={handleFilter}
                        >
                          <div className="col-md-12">
                            <h5 className="text-primary">Train Details</h5>
                          </div>
                          <div className="col-sm-5 mb-2">
                            <label htmlFor="">Wagon Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="wagon"
                            />
                          </div>
                          <div className="col-sm-5 mb-2">
                            <label htmlFor="">Container Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="container"
                            />
                          </div>
                          <div className="col-sm-2">
                            <button
                              type="submit"
                              className="btn btn-label-primary mt-2"
                            >
                              <i className="ri ri-arrow-right-line"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-11 m-auto">
                    <div className="card my-3">
                      <div className="card-body px-2">
                        {advanceSummeryTrain &&
                          Object.entries(advanceSummeryTrain).map(
                            ([trainNo, trainData]) => (
                              <React.Fragment key={trainNo}>
                                <div className="table-responsive text-nowrap mb-4">
                                  <h5 className="text-primary">
                                    Train: <b>{trainNo}</b>
                                  </h5>
                                  <table className="table table-hover table-bordered table-striped ">
                                    <thead>
                                      <tr className="table-primary">
                                        <td>#</td>
                                        <td>Container Number</td>
                                        <td>Seal Number</td>
                                        <td>Action</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {trainData &&
                                        trainData.map((summery, index) => (
                                          <tr key={index}>
                                            <td>
                                              <span>{index + 1}</span>
                                            </td>
                                            <td>
                                              <span>
                                                C : {summery.container_no}
                                              </span><br />
                                              <span>
                                               W : {summery.wagon_no}
                                              </span>
                                            </td>
                                            <td>
                                              {summery.status == 1 ? (
                                                summery.seal_1_no
                                              ) : editAbleSeal == summery.id ? (
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue={summery.ccls_SEAL_NO}
                                                  onChange={(e) =>
                                                    setSeal(e.target.value)
                                                  }
                                                />
                                              ) : (
                                                <span>
                                                  {summery.ccls_SEAL_NO ? (
                                                    summery.ccls_SEAL_NO
                                                  ) : (
                                                    <span>Empty</span>
                                                  )}

                                                  <button
                                                    className="btn btn-label-primary btn-sm p-1 ms-2"
                                                    onClick={() => {
                                                      setEditAbleSeal(
                                                        summery.id
                                                      );
                                                    }}
                                                  >
                                                    <i className="ri-edit-box-line"></i>
                                                  </button>
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {summery.status == 1 ? (
                                                <div className="d-flex gap-2">
                                                  {summery.is_container_damage ==
                                                  "YES" ? (
                                                    <button className="btn btn-label-danger btn-sm">
                                                      Damage
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <button className="btn btn-label-success btn-sm ">
                                                    <i className="ri-verified-badge-fill"></i>
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="d-flex gap-2">
                                                  {editAbleDamage ==
                                                  summery.id ? (
                                                    isDamage ? (
                                                      <button
                                                        className="btn btn-label-danger btn-sm"
                                                        onClick={() =>
                                                          handleDamageClick(
                                                            summery.id
                                                          )
                                                        }
                                                      >
                                                        Damage
                                                      </button>
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    <button
                                                      className="btn btn-outline-danger btn-sm"
                                                      onClick={() =>
                                                        handleDamageClick(
                                                          summery.id
                                                        )
                                                      }
                                                    >
                                                      Damage
                                                    </button>
                                                  )}
                                                  <button
                                                    className="btn btn-label-primary btn-sm "
                                                    onClick={() =>
                                                      handleVerify(summery)
                                                    }
                                                  >
                                                    <i className="ri-verified-badge-fill"></i>
                                                    Verify
                                                  </button>
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        {TrainData &&
                          Object.entries(TrainData).map(
                            ([trainNo, trainData]) => (
                              <React.Fragment key={trainNo}>
                                <div className="table-responsive text-nowrap mb-4">
                                  <h5 className="text-primary">
                                    Train: <b>{trainNo}</b>
                                  </h5>
                                  <table className="table table-hover table-bordered table-striped ">
                                    <thead>
                                      <tr className="table-primary">
                                        <td>#</td>
                                        <td>Container Number</td>
                                        <td>Seal Number</td>
                                        <td>Action</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {trainData &&
                                        trainData.map((summery, index) => (
                                          <tr key={index}>
                                            <td>
                                              <span>{index + 1}</span>
                                            </td>
                                            <td>
                                              <span>
                                                C : {summery.container_no}
                                              </span><br />
                                              <span>
                                               W : {summery.wagon_no}
                                              </span>
                                            </td>
                                            <td>
                                              {summery.status == 1 ? (
                                                summery.seal_1_no
                                              ) : editAbleSeal == summery.id ? (
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue={summery.ccls_SEAL_NO}
                                                  onChange={(e) =>
                                                    setSeal(e.target.value)
                                                  }
                                                />
                                              ) : (
                                                <span>
                                                  {summery.ccls_SEAL_NO ? (
                                                    summery.ccls_SEAL_NO
                                                  ) : (
                                                    <span>Empty</span>
                                                  )}

                                                  <button
                                                    className="btn btn-label-primary btn-sm p-1 ms-2"
                                                    onClick={() => {
                                                      setEditAbleSeal(
                                                        summery.id
                                                      );
                                                    }}
                                                  >
                                                    <i className="ri-edit-box-line"></i>
                                                  </button>
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              {summery.status == 1 ? (
                                                <div className="d-flex gap-2">
                                                  {summery.is_container_damage ==
                                                  "YES" ? (
                                                    <button className="btn btn-label-danger btn-sm">
                                                      Damage
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <button className="btn btn-label-success btn-sm ">
                                                    <i className="ri-verified-badge-fill"></i>
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="d-flex gap-2">
                                                  {editAbleDamage ==
                                                  summery.id ? (
                                                    isDamage ? (
                                                      <button
                                                        className="btn btn-label-danger btn-sm"
                                                        onClick={() =>
                                                          handleDamageClick(
                                                            summery.id
                                                          )
                                                        }
                                                      >
                                                        Damage
                                                      </button>
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    <button
                                                      className="btn btn-outline-danger btn-sm"
                                                      onClick={() =>
                                                        handleDamageClick(
                                                          summery.id
                                                        )
                                                      }
                                                    >
                                                      Damage
                                                    </button>
                                                  )}
                                                  <button
                                                    className="btn btn-label-primary btn-sm "
                                                    onClick={() =>
                                                      handleVerify(summery)
                                                    }
                                                  >
                                                    <i className="ri-verified-badge-fill"></i>
                                                    Verify
                                                  </button>
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </React.Fragment>
                            )
                          )}

                          {advanceSummeryTrain.length === 0 && TrainData.length===0  && (
                            <p className="text-center text-danger">No Data Found</p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Seal Number
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <hr />
                    <form action="">
                      <div className="modal-body py-0">
                        <div action="" className="row align-items-center">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="" className="mb-1">
                              IS Seal
                            </label>
                            <div className="d-flex gap-2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="seal"
                                  id="Yes"
                                  value="Yes"
                                  // checked={isSeal}
                                  // onChange={() => setISSeal(!isSeal)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="seal"
                                  id="No"
                                  value="No"
                                  // checked={!isSeal}
                                  // onChange={() => setISSeal(!isSeal)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="No"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* {isSeal && ( */}
                          <div className="col-md-12 mb-3">
                            <label htmlFor="" className="mb-1">
                              Seal Number
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="seal_number"
                              value={seal}
                            />
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <hr />
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
      </div>
    </>
  );
}
