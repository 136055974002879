import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            {/* <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl">
                    <div
                        className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
                            <div className="mb-2 text-center text-dark w-100">
                                &copy; EasyAIConnect , made with <i className="tf-icons ri-heart-fill text-danger"></i> by <a href="#" className="text-primary">EasyAIConnect</a>
                            </div>
                    </div>
                </div>
            </footer> */}
        </>

    );
}