import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  const user_permissions = localStorage.getItem("user_permissions");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (user_permissions) {
      // Parse the JSON string back into an object
      const parsedPermissions = JSON.parse(user_permissions);
      setPermissions(parsedPermissions);
      console.log(parsedPermissions); 
       // This will now log the object correctly
     
    }
  }, [user_permissions]);
  const initialTheme = sessionStorage.getItem("myTheme") === "true";
  const [theme, setTheme] = useState(initialTheme);
  const effectTrigger = useRef(false);

  const changeTheme = () => {
    setTheme((prevTheme) => !prevTheme);
    effectTrigger.current = !effectTrigger.current;
  };

  useEffect(() => {
    if (effectTrigger.current) {
      effectTrigger.current = !effectTrigger.current;
      sessionStorage.setItem("myTheme", theme);
      const coreCss = document.querySelector(".template-customizer-core-css");
      const themeCss = document.querySelector(".template-customizer-theme-css");

      if (theme) {
        document.documentElement.setAttribute("data-style", "dark");
        if (coreCss && themeCss) {
          coreCss.setAttribute("href", "/assets/vendor/css/rtl/core-dark.css");
          themeCss.setAttribute(
            "href",
            "/assets/vendor/css/rtl/theme-default-dark.css"
          );
        }
      } else {
        document.documentElement.setAttribute("data-style", "light");
        if (coreCss && themeCss) {
          coreCss.setAttribute("href", "/assets/vendor/css/rtl/core.css");
          themeCss.setAttribute(
            "href",
            "/assets/vendor/css/rtl/theme-default.css"
          );
        }
      }
    }
  }, [theme]);

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-xxl">
          <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
            <Link to={'/'} className="app-brand-link gap-2">
              <span className="app-brand-logo demo">
                <span style={{ color: "var(--bs-primary)" }}>
                  <svg
                    width={30}
                    height={24}
                    viewBox="0 0 250 196"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.3002 1.25469L56.655 28.6432C59.0349 30.1128 60.4839 32.711 60.4839 35.5089V160.63C60.4839 163.468 58.9941 166.097 56.5603 167.553L12.2055 194.107C8.3836 196.395 3.43136 195.15 1.14435 191.327C0.395485 190.075 0 188.643 0 187.184V8.12039C0 3.66447 3.61061 0.0522461 8.06452 0.0522461C9.56056 0.0522461 11.0271 0.468577 12.3002 1.25469Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.077704"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 65.2656L60.4839 99.9629V133.979L0 65.2656Z"
                      fill="black"
                    />
                    <path
                      opacity="0.077704"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 65.2656L60.4839 99.0795V119.859L0 65.2656Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M237.71 1.22393L193.355 28.5207C190.97 29.9889 189.516 32.5905 189.516 35.3927V160.631C189.516 163.469 191.006 166.098 193.44 167.555L237.794 194.108C241.616 196.396 246.569 195.151 248.856 191.328C249.605 190.076 250 188.644 250 187.185V8.09597C250 3.64006 246.389 0.027832 241.935 0.027832C240.444 0.027832 238.981 0.441882 237.71 1.22393Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.077704"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M250 65.2656L189.516 99.8897V135.006L250 65.2656Z"
                      fill="black"
                    />
                    <path
                      opacity="0.077704"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M250 65.2656L189.516 99.0497V120.886L250 65.2656Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2787 1.18923L125 70.3075V136.87L0 65.2465V8.06814C0 3.61223 3.61061 0 8.06452 0C9.552 0 11.0105 0.411583 12.2787 1.18923Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2787 1.18923L125 70.3075V136.87L0 65.2465V8.06814C0 3.61223 3.61061 0 8.06452 0C9.552 0 11.0105 0.411583 12.2787 1.18923Z"
                      fill="white"
                      fillOpacity="0.15"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M237.721 1.18923L125 70.3075V136.87L250 65.2465V8.06814C250 3.61223 246.389 0 241.935 0C240.448 0 238.99 0.411583 237.721 1.18923Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M237.721 1.18923L125 70.3075V136.87L250 65.2465V8.06814C250 3.61223 246.389 0 241.935 0C240.448 0 238.99 0.411583 237.721 1.18923Z"
                      fill="white"
                      fillOpacity="0.3"
                    />
                  </svg>
                </span>
              </span>
              <span className="app-brand-text demo menu-text fw-semibold ms-1">
                Dashboard
              </span>
            </Link>
            <Link
              className="layout-menu-toggle menu-link text-large ms-auto d-xl-none"
            >
              <i className="ri-close-fill align-middle" />
            </Link>
          </div>
          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
            <Link className="nav-item nav-link px-0 me-xl-6">
              <i className="ri-menu-fill ri-24px" />
            </Link>
          </div>
          <div
            className="navbar-nav-right d-flex align-items-center"
            id="navbar-collapse"
          >
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item">
                <div className="me-5">
                  {
                    permissions &&
                    [...new Set(permissions.map(permission => permission.application.url))] // Remove duplicates
                      .map((url, i) => (
                        ["https://tasapp.easyaiconnect.com/gate",
                          "https://tasapp.easyaiconnect.com/rake",
                          "https://tasapp.easyaiconnect.com/CWHCartingReadLCL",
                          "https://tasapp.easyaiconnect.com/CWHCartingRead",
                          "https://tasapp.easyaiconnect.com/CWHDeliveryRead",
                          "https://tasapp.easyaiconnect.com/CWHDeStuffingRead",
                          "https://tasapp.easyaiconnect.com/CWHDeStuffingReadLCL",
                          "https://tasapp.easyaiconnect.com/CWHStuffingReadFCL",
                          "https://tasapp.easyaiconnect.com/CWHStuffingRead"].includes(url) ? (
                          <a
                            key={i}
                            href={url}
                            className="btn btn-sm btn-outline-success ms-2 mt-2"
                          >
                            {url.replace("https://tasapp.easyaiconnect.com/", "")}
                          </a>
                        ) : null
                      ))
                  }


                </div>
              </li>
              <li className="nav-item ">
                <h4 className="mb-0 me-4">{time.toLocaleTimeString()}</h4>
              </li>
              <li className="nav-item me-3">
                <Link
                  className="nav-link btn btn-text-secondary rounded-pill btn-icon" onClick={changeTheme}
                >
                  <i className={`${theme ? "ri-moon-line" : "ri-sun-line"
                    }  ri-22px`}
                  />
                </Link>
              </li>

              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Link
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  data-bs-toggle="dropdown"
                >
                  <div className="avatar avatar-online">
                    <img
                      src="/assets/img/avatars/1.png"
                      alt=""
                      className="w-px-40 h-auto rounded-circle"
                    />
                  </div>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end mt-3 py-2">
                  <li>
                    <Link
                      className="dropdown-item"
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <div className="avatar avatar-online">
                            <img
                              src="/assets/img/avatars/1.png"
                              alt=""
                              className="w-px-40 h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0 small">John Doe</h6>
                          <small className="text-muted">Admin</small>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      <i className="ri-user-3-line ri-22px me-2" />
                      <span className="align-middle">My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                    >
                      <i className="ri-settings-4-line ri-22px me-2" />
                      <span className="align-middle">Settings</span>
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider" />
                  </li>
                  <li>
                    <div className="d-grid px-4 pt-2 pb-1">
                      <Link
                        className="btn btn-danger d-flex"
                        to={'/'}
                      >
                        <small className="align-middle">Logout</small>
                        <i className="ri-logout-box-r-line ms-2 ri-16px" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* Search Small Screens */}
          <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
            <input
              type="text"
              className="form-control search-input border-0"
              placeholder="Search..."
              aria-label="Search..."
            />
            <i className="ri-close-fill search-toggler cursor-pointer" />
          </div>
        </div>
      </nav>

    </>

  );
}