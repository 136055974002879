import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  HashRouter,
  Navigate
} from "react-router-dom";

import Index from "./pages/index";
import IndexR from "./pages/IndexR";
import { useEffect } from "react";
import Login from "./pages/Login";
import "./App.css"

import CWHCartingReadLCL from "./pages/CWHCartingReadLCL";
import CWHCartingRead from "./pages/CWHCartingRead";
import CWHDeliveryRead from "./pages/CWHDeliveryRead";
import CWHDeStuffingRead from "./pages/CwhDestuffingRead";
import CWHDeStuffingReadLCL from "./pages/CwhDestuffingReadLcl";
import CWHStuffingReadFCL from "./pages/CwhStuffingReadFcl";
import CWHStuffingRead from "./pages/CwhStuffingLCL";
import TallySheet from "./pages/TallySheet";
import PrivateRoute from "./pages/PrivateRoute";

export default function App() {

  const initialTheme = sessionStorage.getItem("myTheme") === "true";
  useEffect(() => {
    const coreCss = document.querySelector(".template-customizer-core-css");
    const themeCss = document.querySelector(".template-customizer-theme-css");

    if (initialTheme) {
      document.documentElement.setAttribute("data-style", "dark");
      if (coreCss && themeCss) {
        coreCss.setAttribute("href", "/assets/vendor/css/rtl/core-dark.css");
        themeCss.setAttribute(
          "href",
          "/assets/vendor/css/rtl/theme-default-dark.css"
        );
      }
    } else {
      document.documentElement.setAttribute("data-style", "light");
      if (coreCss && themeCss) {
        coreCss.setAttribute("href", "/assets/vendor/css/rtl/core.css");
        themeCss.setAttribute(
          "href",
          "/assets/vendor/css/rtl/theme-default.css"
        );
      }
    }
  }, []);
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/TallySheet" element={<TallySheet />} />
          
          <Route path="/gate" element={  <PrivateRoute >< Index /></PrivateRoute>} />
          <Route path="/rake" element={ <PrivateRoute><IndexR /></PrivateRoute>} />

          <Route path="/CWHCartingReadLCL" element={ <PrivateRoute><CWHCartingReadLCL /></PrivateRoute>} />
          <Route path="/CWHCartingRead" element={ <PrivateRoute><CWHCartingRead /></PrivateRoute>} />
          <Route path="/CWHDeliveryRead" element={ <PrivateRoute><CWHDeliveryRead /></PrivateRoute>} />
          <Route path="/CWHDeStuffingRead" element={ <PrivateRoute><CWHDeStuffingRead /></PrivateRoute>} />
          <Route path="/CWHDeStuffingReadLCL" element={ <PrivateRoute><CWHDeStuffingReadLCL /></PrivateRoute>} />
          <Route path="/CWHStuffingReadFCL" element={ <PrivateRoute><CWHStuffingReadFCL /></PrivateRoute>} />
          <Route path="/CWHStuffingRead" element={ <PrivateRoute><CWHStuffingRead /></PrivateRoute>} />

        </Routes>
      </div>
    </Router>

  );
}