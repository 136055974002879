import React, { useRef } from 'react';
const PrintWrapper = ({ children }) => (
    <div
        style={{
            fontSize: "14px", // Adjust font size for print readability
            margin: "0 auto",
            "@media print": {
                tableLayout: "fixed", // Ensures table columns align
                pageBreakInside: "avoid", // Prevents page breaks inside tables
            },
        }}
    >
        {children}
    </div>
);

const TallySheet = () => {
    const printRef = useRef();

    const handlePrint = () => {
      const printContents = printRef.current.innerHTML;
      const headContents = document.head.innerHTML; // Get the current page's <head> content
      const printWindow = window.open("", "_blank");
  
      printWindow.document.write(`
        <html>
          <head>
            <!-- Include the current page's head content -->
            ${headContents}
            
            <!-- Add the custom links you provided -->
            <link rel="icon" type="image/x-icon" href="/assets/img/favicon/favicon.ico" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="/assets/vendor/fonts/remixicon/remixicon.css" />
            <link rel="stylesheet" href="/assets/vendor/fonts/flag-icons.css" />
            <link rel="stylesheet" href="/assets/vendor/libs/node-waves/node-waves.css" />
            <link rel="stylesheet" href="/assets/vendor/css/rtl/core.css" class="template-customizer-core-css" />
            <link rel="stylesheet" href="/assets/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
            <link rel="stylesheet" href="/assets/css/demo.css" />
            <link rel="stylesheet" href="/assets/css/style.css" />
            <link rel="stylesheet" href="/assets/vendor/libs/spinkit/spinkit.css" />
            <link rel="stylesheet" href="/assets/vendor/css/pages/page-auth.css" />
            
            <!-- You can also add custom styles for printing here -->
            <style>
              /* Optional print-specific styles */
              @media print {
                body {
                  font-family: 'Inter', sans-serif;
                }
              }
            </style>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
  
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
    return (
        <>
        <button onClick={handlePrint}>Print</button>
        <div className="container my-4" ref={printRef} >
            <div className="row mb-5 border-bottom border-dark">
                <div className="col-md-4 col-sm-4">
                    <div><strong>S. No: 14139</strong>
                        <div><strong>Date: 12-Dec-2024</strong></div></div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className='text-center'><strong>Cargo Handling Operator </strong>
                        <div>Container Stuffing Tally Sheet</div>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-borderless table-sm d-print-table mb-4 table-font text-nowrap">
                    <tbody>
                        <tr>
                            <td><strong>CRN Number</strong></td>
                            <td>: F24120012</td>
                            <td><strong>BlNo Code</strong></td>
                            <td>: 1</td>
                        </tr>
                        <tr>
                            <td><strong>Container Number</strong></td>
                            <td>: CSNU1418382</td>
                            <td><strong>Declared Gross Weight</strong></td>
                            <td>: 2.4 Tons</td>
                        </tr>
                        <tr>
                            <td><strong>Container Size</strong></td>
                            <td>: 20</td>
                            <td><strong>Warehouse Name</strong></td>
                            <td>: Export Warehouse</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>: FCL</td>
                            <td><strong>Stuffing Start Date & Time</strong></td>
                            <td>: 12-Dec-2024 15:30:00</td>
                        </tr>
                        <tr>
                            <td><strong>GW Port Code</strong></td>
                            <td>: 2012</td>
                            <td><strong>Stuffing End Date & Time</strong></td>
                            <td>: 12-Dec-2024 16:40:00</td>
                        </tr>
                        <tr>
                            <td><strong>Total No. of Packages Declared</strong></td>
                            <td>: 65</td>
                            <td><strong>Excess / Short Packages</strong></td>
                            <td>: 0 / 5</td>
                        </tr>
                        <tr>
                            <td><strong>Handling Type</strong></td>
                            <td>: LCH</td>
                            <td><strong>Exporter Name</strong></td>
                            <td>: </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="table-responsive ">

                <table className="table table-bordered table-sm d-print-table table-font text-nowrap ">
                    <thead className="table-light">
                        <tr>
                            <th>Container Number</th>
                            <th>Pkg Code</th>
                            <th>Cargo Description (Code)</th>
                            <th>No. of Pkgs</th>
                            <th>Pkg Weight</th>
                            <th>Grid Locations</th>
                            <th>Area (Sqm)</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td className='text-danger'>CSNU1418382</td>
                            <td>PK</td>
                            <td>HOUSEHOLD GOODS n ITEMS (1376)</td>
                            <td>65</td>
                            <td>2.4</td>
                            <td>X04</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>CSNU1418382</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td>65</td>
                            <td>2.40</td>
                            <td></td>
                            <td>16.00</td>
                        </tr>
                        <tr>
                            <td colspan="7" rowspan="4">
                                <strong>Remarks:</strong>
                                <p>This section is reserved for any additional comments or notes.</p>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                    </tbody>
                </table>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 col-sm-7">
                    <p>Said to contain received cargo in sound <br /> condition and to my entire satisfaction.</p>

                </div>
                <div className="col-md-2 col-sm-2"></div>
                <div className="col-md-3 col-sm-3">
                    <div><strong>Tallied By</strong><br /> Ravi Kumar</div>

                </div>
            </div>

        </div>
        </>
    );
};

export default TallySheet;
